import { observable, toJS } from "mobx";
import * as moment from "moment";
import TrackableCollection from "../core/TrackableCollection";
import TrackableModel from "../core/TrackableModel";
import { AddressSearchCriteriaValue } from "../mustangui/AddressSearchCriteria";
import { AccessLevel } from "../mustangui/Api";
import { DateRangeCriteriaValue } from "../mustangui/DateRangeCriteria";
import { PaneDataRow } from "../stores/PaneDataStore";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RuntimeProperties = { [id: string]: any };

type Widgets = { [id: string]: RuntimeWidget };

export type WidgetValue =
  | boolean
  | Date
  | number
  | string
  | string[]
  | null
  | AddressSearchCriteriaValue
  | DateRangeCriteriaValue;

export interface RuntimeWidget {
  properties: RuntimeProperties;
  value: WidgetValue;
  widgetTypeId: number;
}

export interface RuntimeWidgetT<
  ValueType extends WidgetValue,
  PropertiesType extends RuntimeProperties
> {
  properties: PropertiesType;
  value: ValueType;
  widgetTypeId: number;
}

export interface WidgetData {
  [id: string]: RuntimeWidget;
}

export default class PaneRow extends TrackableModel {
  // The date format used to serialize dates to strings when sending them over
  // the wire to the AppServer and back.
  private static serializeDateFormat: string = "YYYY-MM-DD";

  @observable private rowWidgets: Widgets = {};

  public criteriaWidgetNames: string[];
  public currentJobLevel: number | null = null;
  public hierarchyLevel: number | null = null;
  public isCurrentJob: boolean | null = null;
  public isNew: boolean = false;
  @observable public isVisible: boolean = false;
  public objectHandle: string;
  public rowKey: string;

  /**
   * Deserializes a date from the format that is used to send dates from
   * the AppServer.
   */
  public static deserializeDateValue(
    serializedDate: string | null
  ): Date | null {
    if (serializedDate === null) {
      return null;
    }

    return moment(serializedDate, PaneRow.serializeDateFormat).toDate();
  }

  /**
   * Serializes a widget's date value into a format that can be used for
   * sending the date to the AppServer.
   */
  public static serializeDateValue(date: Date | null): string | null {
    if (date === null) {
      return null;
    }

    return moment(date).format(PaneRow.serializeDateFormat);
  }

  public get widgets(): Widgets {
    return this.rowWidgets;
  }

  public set widgets(value: Widgets) {
    for (const widgetName of Object.keys(value)) {
      const newWidget = value[widgetName] as RuntimeWidget;
      if (widgetName in this.rowWidgets) {
        const widget = this.rowWidgets[widgetName] as RuntimeWidget;
        for (const prop of Object.keys(newWidget.properties)) {
          // FUTURE
          // This check is used to detect if the property is an array
          // observable. It is safe right now because there are no
          // runtime properties on the widgets that are objects.
          //
          // We may want to add support for objects, though that would
          // essentially amount to implementing a recursive traversal,
          // which greatly increases the complexity of this method.
          const oldValue = widget.properties[prop];
          const newValue = newWidget.properties[prop];
          if (newValue instanceof Object) {
            if (typeof oldValue.replace !== "function") {
              throw new Error(
                "Observable arrays are the only" +
                  " objects supported in runtime data. " +
                  `Widget: "${widgetName}" ` +
                  `Property: "${prop}"`
              );
            }

            if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
              // Clone object / array properties
              oldValue.replace(toJS(newValue));
            }
          } else {
            widget.properties[prop] = newValue;
          }
        }
        widget.value = value[widgetName].value;
      } else {
        this.rowWidgets[widgetName] = observable(newWidget);
      }
    }
  }

  public static get(
    dataId: string,
    rowKey: string | null = null
  ): PaneRow | null {
    if (!TrackableModel.models.has(dataId)) {
      return null;
    }

    const collection = TrackableModel.models.get(dataId) as TrackableCollection;

    const observableCollection = collection.observableCollection
      ? collection.observableCollection
      : collection;

    if (observableCollection.length === 0) {
      return null;
    }

    if (!rowKey) {
      if (observableCollection.length > 1) {
        throw new Error(
          "A rowKey is required if the TrackableCollection has more " +
            "than one item"
        );
      }

      return observableCollection[0] as PaneRow;
    }

    const model = observableCollection.find((m) =>
      m["rowKey"].startsWith(rowKey)
    );

    if (!model) {
      return null;
    }

    return model as PaneRow;
  }

  /* @deprecated Use PaneRow.getWidgetT<>() instead. */
  public static getWidgetProperties(
    dataId: string,
    widgetName: string
  ): RuntimeProperties {
    if (!TrackableModel.models.has(dataId)) {
      throw Error(`Could not find collection with dataId ${dataId}`);
    }

    const collection = TrackableModel.models.get(dataId) as TrackableCollection;

    if (!collection.isLoaded) {
      throw Error(`Collection with dataId ${dataId} has not yet been loaded`);
    }

    const observableCollection = collection.observableCollection
      ? collection.observableCollection
      : collection;

    const paneRow = observableCollection[0] as PaneRow;

    return paneRow.getWidget(widgetName).properties;
  }

  protected getPropertyNames(): string[] {
    return Object.keys(this.widgets);
  }

  protected loadData(data: TrackableModel) {
    const row = data as PaneRow;
    this.criteriaWidgetNames = [];
    this.currentJobLevel = row.currentJobLevel;
    this.hierarchyLevel = row.hierarchyLevel;
    this.isCurrentJob = row.isCurrentJob;
    this.isNew = row.isNew;
    this.isVisible = true;
    this.objectHandle = row.objectHandle;
    this.rowKey = row.rowKey;
    this.widgets = row.widgets;
  }

  protected setPropertyValue(propName: string, value: WidgetValue): void {
    this.widgets[propName].value = value;
  }

  public loadCriteriaData(
    data: PaneDataRow,
    criteriaWidgetNames: string[]
  ): void {
    this.clear(false);
    this.isLoaded = true;
    this.isModified = criteriaWidgetNames.length > 0;
    this.criteriaWidgetNames = criteriaWidgetNames;
    this.trackUndo = false;

    this.currentJobLevel = null;
    this.hierarchyLevel = null;
    this.isCurrentJob = null;
    this.isNew = false;
    this.isVisible = true;
    this.objectHandle = data.objectHandle;
    this.rowKey = data.rowKey;
    this.widgets = data.widgets;

    for (const propertyName of this.getPropertyNames()) {
      this.initialValues[propertyName] = this.getPropertyValue(propertyName);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getModifiedPropertyValues(): { [id: string]: any } {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const props: { [id: string]: any } = {};
    for (const propertyName of this.getPropertyNames()) {
      if (
        this.hasChanges(propertyName) ||
        (this.criteriaWidgetNames.includes(propertyName) &&
          (this.getReadOnlyProperties(propertyName)[
            "accessLevel"
          ] as AccessLevel) >= AccessLevel.enterable)
      ) {
        props[propertyName] = this.getPropertyValue(propertyName);
      }
    }

    return props;
  }

  public getPrimaryKey(): string {
    return this.rowKey;
  }

  public getPropertyValue(propName: string): WidgetValue {
    return this.widgets[propName].value;
  }

  /* @deprecated Use PaneRow.getWidgetT<>() instead. */
  public getReadOnlyProperties(propName: string): RuntimeProperties {
    return this.widgets[propName].properties;
  }

  /* @deprecated Use PaneRow.getWidgetT<>() instead. */
  public getWidget(widgetName: string): RuntimeWidget {
    return this.widgets[widgetName];
  }

  public getWidgetT<
    ValueType extends WidgetValue,
    PropertiesType extends RuntimeProperties
  >(widgetName: string): RuntimeWidgetT<ValueType, PropertiesType> {
    return this.widgets[widgetName] as RuntimeWidgetT<
      ValueType,
      PropertiesType
    >;
  }

  public updateWidget(widgetName: string, widgetData: RuntimeWidget): void {
    this.widgets[widgetName] = widgetData;
  }
}

TrackableModel.setPrimaryKeyName("PaneRow", "rowKey");
